<template>
  <div class="my-13">
    <div> 
      <h3 class="text-Keep-Calm-Heavy text-1B1464 text-center padding-20 text-size-titulo-m"> TESTIMONIOS DE NUESTROS CLIENTES</h3>
      <div >
        <v-carousel
          height="max-height"
          hide-delimiters
          v-if="testimonios.length>0"
        >
        <template v-slot:prev="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-D24748 size-icon-lr">mdi-menu-left</v-icon></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-D24748 size-icon-lr">mdi-menu-right</v-icon></v-btn>
          </template>   
        <v-row >
          <v-col
            cols="10"
            offset="1"
          >     
          <v-carousel-item  
            v-for="(j, i) in 
            (Math.ceil(testimonios.length/columns))"
            v-bind:key="i"
           >
          
              <v-row >
                <template
                v-for="(testi, index) in testimonios" v-bind:key="index" >
                <v-col  
                  :cols="col_tam"
                  v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                >
                
                <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                  <div
                    class="mx-auto  mb-6"
                    max-width="344"
                  >
                  <div class="d-flex justify-center">
                    
                    <iframe 
                        :src="baseVideo+''+testi.video" 
                        class="embed-responsive-item video-width" 
                        frameborder="0"  allowfullscreen cover></iframe>
                  </div>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="card-title text-001C29 text-keep-calm-font text-center">{{testi.titulo}}</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                     <p class="text-MontserratAlternates-Regular">{{testi.descripcion}}</p>
                    </v-card-subtitle>
                  </div>
                  </div>
                </v-col>
                </template>
              </v-row>
              
          </v-carousel-item>
          </v-col>
        </v-row>
           
        </v-carousel>
      </div>
    </div>
  </div>

</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import axios from 'axios'
var url ='backend-esamlatam/crud-testimonio.php';

export default {
  name: 'EsamTestimonio',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
   
      mostrar(){
        axios.post(this.baseUrl+''+url,{opcion:1})
        .then(Response =>{
          this.testimonios=Response.data
        })
      },
 },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
      baseVideo : "https://www.youtube.com/embed/",
      testimonios : [],
      testimonio : [],
    }
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }
      if (screen.width > 961 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1265 && screen.width <= 1904)
      {
        return 4;
      }
      if (screen.width > 1904) {
        return 4;
      }
      return 1;
    },
    col_tam() {
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 961 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1265 && screen.width <= 1904) {
        return 3;
      }
      if (screen.width > 1904) {
        return 3;
      }
      return 1;
    }
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>

@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 

 }
@media (min-width: 1905px)  { 
 
 }

</style>
