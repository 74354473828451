<template>
    <v-card>
    <v-window v-model="onboarding">
      <template 
        v-for="(slid, index) in sliders" v-bind:key="index" >
        <v-window-item>
          <v-img
            :src="baseUrl+''+baseImagen+''+slid.imagen"
            width="100%"
          >
            <h4 class="text-center text-DA2F42 text-MyriadPro-Regular text-size-title padding-text-title">{{slid.titulo}}</h4>
          </v-img>
      </v-window-item>
      </template>
      
    </v-window>
<div class="d-flex justify-center">
    <v-card-actions class="padding-action ">
      <v-btn
        variant="plain"
        icon="mdi-menu-left"
        class="text-size-lr text-D24748"
        :size="height_bootom"
        @click="prev"
      ></v-btn>
      <v-item-group
        v-model="onboarding"
        class="text-center"
        mandatory
      >
        <v-item
          v-for="n in length"
          :key="`btn-${n}`"
          v-slot="{ isSelected, toggle }"
        >
          <v-btn
            :size="height_bootom"
            class="text-white text-size-center"
            :variant="isSelected ? 'outlined' : 'text'"
            icon="mdi-record"
            @click="toggle"
          ></v-btn>
        </v-item>
      </v-item-group>
      <v-btn
        variant="plain"
        icon="mdi-menu-right "
        class="text-size-lr text-D24748"
        :size="height_bootom"
        @click="next"
      ></v-btn>
    </v-card-actions>
    </div>
  </v-card>
  
</template>

<script>


import axios from 'axios'
var url ='backend-tesis/crud-slider.php';


export default {
  name: 'TesisCarousel',
  props: {
  },
  components : {
  },
 methods: {
  mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,tipo:"S"})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
     next () {
        this.onboarding = this.onboarding + 1 >= this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 <= -1
          ? (this.length-1)
          : this.onboarding - 1
      },
 },
  data(){
    return {
        sliders : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://www.tesis.com.bo/",
        baseImagen : "img-tesis/img-slider/",
        length: 3,
      onboarding: 1,
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>
/** Mobile Devices  **/

@media (min-width: 0px) and (max-width: 601px) { 
  .text-size-title{
    font-size: 5.5vw;
  }
  .padding-text-title{
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0%;
    padding-top: 3%;
    } 
  .padding-action{
  bottom: 0%;
  top: 59%;
  position: absolute;
}
.text-size-lr{
  font-size: 10vw;
}
.text-size-center{
  font-size: 5vw;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  .text-size-title{
    font-size: 5.5vw;
  }
  .padding-text-title{
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0%;
    padding-top: 3%;
    } 
    .padding-action{
  bottom: 0%;
  top: 80%;
  position: absolute;
}
.text-size-lr{
  font-size: 5vw;
}
.text-size-center{
  font-size: 3vw;
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
     .text-size-title{
    font-size: 5.5vw;
  }
  .padding-text-title{
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0%;
    padding-top: 3%;
    }  
.padding-action{
  bottom: 0%;
  top: 84%;
  position: absolute;
}
.text-size-lr{
  font-size: 3vw;
}
.text-size-center{
  font-size: 1.8vw;
}
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
     .text-size-title{
    font-size: 5.5vw;
  }
  .padding-text-title{
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0%;
    padding-top: 3%;
    }  
   .padding-action{
  bottom: 0%;
  top: 86%;
  position: absolute;
}
.text-size-lr{
  font-size: 1.7vw;
}
.text-size-center{
  font-size: 1vw;
}
 }
@media (min-width: 1905px)  { 
     .text-size-title{
    font-size: 5.5vw;
  }
  .padding-text-title{
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0%;
    padding-top: 3%;
    }  
.padding-action{
  bottom: 0%;
  top: 90%;
  position: absolute;
}
.text-size-lr{
  font-size: 1.2vw;
}
.text-size-center{
  font-size: 0.7vw;
}

}
</style>
