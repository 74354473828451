<template>
  <v-carousel
    cycle
    height="max-height"
    hide-delimiter-background
    class="text-white "
    :show-arrows="false"
    :hide-delimiters="true"
  >    
    <template v-slot:prev="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-left</v-icon></v-btn>
        </template>
        <template v-slot:next="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-right</v-icon></v-btn>
        </template> 
    <template 
        v-for="(slid, index) in sliders" v-bind:key="index" >
        <v-carousel-item
            :src="baseUrl+''+baseImagen+''+slid.imagen"
        >
        
            <div class="padding-text" v-if="slid.estado_descripcion===1">
                <h3 class="text-Montserrat-BlackItalic text-titulo-tam">{{slid.titulo}}</h3>
                <p class="text-Montserrat-BlackItalic text-descrip-tam">{{slid.descripcion}}</p>     
            </div>
        </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>

import axios from 'axios'
var url ='backend-tesis/crud-slider.php';


export default {
  name: 'TesisPromocion',
  props: {
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,tipo:"P"})
      .then(Response =>{
        this.sliders=Response.data
      })
    }
 },
  data(){
    return {
        sliders : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://www.tesis.com.bo/",
        baseImagen : "img-tesis/img-slider/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>


/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 


}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    

  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 

 }
@media (min-width: 1905px)  { 
    

}


</style>
