<template>
<div v-if="id === 1">
    <v-row >
      <v-col cols="12"
        class="padding-col"
      >
        <div class=" div-ta0 background-div"></div>
      </v-col>
    </v-row>
</div>
<div v-if="id === 2">
    <v-row >
      <v-col cols="12"
        class="padding-col"
      >
        <div class=" div-ta0 background-div1"></div>
      </v-col>
    </v-row>
</div>
</template>

<script>


export default {
  name: 'TesisSeparador',
  props: {
    id: Number,
  },
  components : {
  },
 methods: { 
   
 },
  data(){
    return {
      carouselheight: 0,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>


 
.background-div{
    background: linear-gradient(90deg, #D24748, #963F53, #281C47);
} 
.background-div1{
    background: linear-gradient(90deg,  #281C47, #963F53,#D24748);
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
 


 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
 
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 

 }
@media (min-width: 1905px)  { 
 
 }



</style>
