<template>
  <div  class="background-d1d0e0">
      <div>
        <h3 class="text-Keep-Calm-Heavy text-1B1464 text-center padding-20 text-size-titulo-m"> TUTORIALES FREE:</h3>
        <v-carousel
          height="max-height"
          show-arrows
          hide-delimiter-background
          hide-delimiters
          v-if="testimonios.length>0"
        >
        <template v-slot:prev="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-D24748 size-icon-lr">mdi-menu-left</v-icon></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-D24748 size-icon-lr">mdi-menu-right</v-icon></v-btn>
          </template>   
        <v-row justify="center">
          <v-col
            cols="8"
            sm="10"
            md="10"
            lg="10"
            xl="10"
          >     
          <v-carousel-item  
            v-for="(j, i) in 
            (Math.ceil(testimonios.length/columns))"
            v-bind:key="i"
           >
          
              <v-row >
                <template
                v-for="(testi, index) in testimonios" v-bind:key="index" >
                <v-col  
                  :cols="col_tam"
                  v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                >
                
                <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                  <div
                    class="mx-auto background-d1d0e0 mb-6"
                    max-width="400"
                  >
                  <div class="d-flex justify-center">
                    
                    <iframe 
                        :src="baseVideo+''+testi.video" 
                        class="embed-responsive-item video-width" 
                        frameborder="0"  allowfullscreen cover></iframe>
                  </div>
                    <h6 class="text-Keep-Calm-Heavy text-1B1464 text-center text-size-titulo-tutorial">{{testi.titulo}}</h6>
                  </div>
                  </div>
                </v-col>
                </template>
              </v-row>
              
          </v-carousel-item>
          </v-col>
        </v-row>
           
        </v-carousel>
      </div>
      <div class="d-flex justify-center padding-20">
        
          <v-btn class="text-Keep-Calm-Book background-982929 text-white nargin-booton" >
            Tutoriales Premium
          </v-btn>
          <v-btn class="text-Keep-Calm-Book background-982929 text-white nargin-booton size-icon" >
            <v-icon>mdi-menu-right</v-icon>
          </v-btn>
      </div>
  </div>

</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import axios from 'axios'
var url ='backend-tesis/crud-tuturial.php';

export default {
  name: 'TesisTutorial',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
        
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    }
  },
 methods: {  
      mostrar(){
        axios.post(this.baseUrl+''+url,{opcion:1,tipo:"F"})
        .then(Response =>{
          this.testimonios=Response.data
        })
      },
 },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://www.tesis.com.bo/",
      baseVideo : "https://www.youtube.com/embed/",
      testimonios : [],
    }
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>


@media (min-width: 0px) and (max-width: 600px) {
.text-size-titulo-tutorial{
  font-size: 1.8vh;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
.text-size-titulo-tutorial{
  font-size: 1.5vh;
}
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.text-size-titulo-tutorial{
  font-size: 1.5vh;
}
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
.text-size-titulo-tutorial{
  font-size: 2.5vh;
}
 
 }

 @media (min-width: 1905px) { 
.text-size-titulo-tutorial{
  font-size: 2.6vh;
}
 
 }

</style>
