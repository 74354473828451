<template>
<Navber/>
<v-row>
    <v-col cols="12">
        <Carousel/>
    </v-col>
    <v-col cols="12" class="padding-col">
        <Separador :id="1"/>
    </v-col>
</v-row>
<Servicio/>
<Tutorial/>
<Separador :id="2"/>
<Asociados/>
<Separador :id="1"/>
<Motivacion/>
<Separador :id="2"/>
<Docente/>
<Testimonio/>
<v-row v-if="1 == 0">
    <v-col cols="12" class="padding-col">
        <Separador :id="1"/>
    </v-col>
    <v-col cols="12" class="padding-col">
        <Promocion/>
    </v-col>
    <v-col cols="12">
        <Separador :id="1"/>
    </v-col>
</v-row>
<Separador :id="1"/>
<Contenido/>
<Contacto/>
<Separador :id="2"/>
</template>

<script>


import Navber from '@/components/Navbar/Navbar.vue';
import Carousel from '@/components/Carousel/Carousel.vue'
import Separador from '@/components/Separador/Separador.vue'
import Servicio from '@/components/Servicio/Servicio.vue'
import Tutorial from '@/components/Tutorial/Tutorial.vue'
import Asociados from '@/components/Asociados/Asociados.vue'
import Motivacion from '@/components/Motivacion/Motivacion.vue'
import Docente from '@/components/Docente/Docente.vue'
import Testimonio from '@/components/Testimonio/Testimonio.vue'
import Promocion from '@/components/Promocion/Promocion.vue'
import Contenido from '@/components/Contenido/Contenido.vue'
import Contacto from '@/components/Contacto/Contacto.vue'
export default {
  name: 'TesisMain',
  props: {
  },
  components : {
    Navber,
    Carousel,
    Separador,
    Servicio,
    Tutorial,
    Asociados,
    Motivacion,
    Docente,
    Testimonio,
    Promocion,
    Contenido,
    Contacto,
  },
 methods: {
   
 },
  data(){
    return {
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.padding-col{
  padding: 0px;
}
.margin-p{
  margin:  50px 0px 50px 0px;
}


/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
     
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  
 }
@media (min-width: 1905px)  { 
}


</style>
