<template>
<div class="pa-5">
<v-row justify="center">
    <v-col 
            cols="12"
            sm="12"
            md="11"
            lg="11"
            xl="11">
        <v-row justify="center">
            <v-col 
                cols="5"
                sm="5"
                md="2"
                lg="2"
                xl="2">   
                <v-img
                    src="../../assets/Logos/tesis1.png"
                    width="100%"
                    aspect-ratio="1"
                >
                </v-img>
            </v-col>
            <v-col 
                cols="10"
                sm="10"
                md="10"
                lg="10"
                xl="10" v-if="!iftable" >
                <v-row justify="center">
                    <v-col 
                    cols="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3">
                        <v-list >
                            <h3 class="text-MyriadPro-Regular text-1B1464 text-size-title-contenido">CONTENIDO</h3>
                            <template  v-for="(categoria, index) in categorias" v-bind:key="index">
                                <v-list-item class="padding-v-list-item">
                                    <v-list-item-title class="text-001C29 text-MyriadPro-Regular text-size-subtitle-contenido"><v-icon class="text-FF2929  text-size-icon">mdi-menu-right</v-icon>{{categoria.titulo}}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                    <v-col 
                    cols="5"
                    sm="5"
                    md="5"
                    lg="5"
                    xl="5">                
                        <v-list >
                            <h3 class="text-MyriadPro-Regular text-1B1464 text-size-title-contenido">SEDE CENTRAL</h3>
                                <v-list-item class="padding-v-list-item">
                                    <v-list-item-title class="text-001C29 text-MyriadPro-Regular text-size-subtitle-contenido">
                                        <v-icon class="text-FF2929 text-size-icon">mdi-menu-right</v-icon>PLAZUELA ZUDAÑEZ - CALLE <br>OLAÑETA Nº 12 SUCRE - BOLIVIA</v-list-item-title>
                                </v-list-item>
                        </v-list>
                        <h3 class="text-Keep-Calm-Book text-1B1464 text-size-title-contenido">+  Seguir TESIS.COM</h3>
                        <v-btn
                            icon="mdi-facebook"
                            class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                            v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                        <v-btn
                            icon="mdi-instagram"
                            class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                            v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                        <v-btn
                            icon="mdi-twitter"
                            class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                            v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                        <v-btn
                            icon="mdi-youtube"
                            class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                            v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                    </v-col>
                    <v-col 
                    cols="4"
                    sm="4"
                    md="4"
                    lg="4"
                    xl="4">
                        <div class="d-flex ">
                            <h3 class="text-MyriadPro-Regular background-1B1464 text-white pa-2 text-size-title-contenido">Contáctanos mediante:</h3>
                        </div>
                        <div class="d-flex mt-7">
                            <v-btn
                                icon="mdi-whatsapp"
                                class="text-size-icon1 background-5bec76 text-white twitter mr-2"
                                v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                            <v-btn
                                icon="mdi-facebook"
                                class="text-size-icon1 text-white twitter mr-2"
                                v-on:click="href('https://twitter.com/EsamLatam')">
                                        <v-img
                                            src="../../assets/Logos/telegram.png"
                                            class="img-width"
                                            aspect-ratio="1"
                                        >
                                        </v-img>
                            </v-btn>
                            <v-btn
                                icon="mdi-facebook-messenger"
                                class="text-size-icon1 text-white background-1c7597 mr-2"
                                v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col 
                cols="7" v-if="iftable" > 
                
                <h3 class="text-Keep-Calm-Book text-1B1464 text-size-title-contenido">+  Seguir TESIS.COM</h3>
                <div class="d-flex">
                    <v-btn
                        icon="mdi-facebook"
                        height="50"
                        width="50"
                        class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                        v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                    <v-btn
                        height="50"
                        width="50"
                        icon="mdi-instagram"
                        class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                        v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                    <v-btn
                        height="50"
                        width="50"
                        icon="mdi-twitter"
                        class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                        v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                    <v-btn
                        height="50"
                        width="50"
                        icon="mdi-youtube"
                        class="text-size-icon1 background-1B1464 text-white twitter mr-2"
                        v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>   
                </div>   
                
                <div class="d-flex ">
                    <h3 class="text-MyriadPro-Regular background-1B1464 text-white pa-1 my-2 text-size-title-contenido">Contáctanos mediante:</h3>
                </div>
                <div class="d-flex my-2">
                    <v-btn
                        height="50"
                        width="50"
                        icon="mdi-whatsapp"
                        class="text-size-icon1 background-5bec76 text-white twitter mr-2"
                        v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                    <v-btn
                        height="50"
                        width="50"
                        icon="mdi-facebook"
                        class="text-size-icon1 text-white twitter mr-2"
                        v-on:click="href('https://twitter.com/EsamLatam')">
                                <v-img
                                    src="../../assets/Logos/telegram.png"
                                    class="img-width"
                                    aspect-ratio="1"
                                >
                                </v-img>
                    </v-btn>
                    <v-btn
                        height="50"
                        width="50"
                        icon="mdi-facebook-messenger"
                        class="text-size-icon1 text-white background-1c7597 mx-2"
                        v-on:click="href('https://twitter.com/EsamLatam')"></v-btn>
                </div>         
                <v-menu >
                    <template v-slot:activator="{ props }">
                        <div class="d-flex justify-end">
                            <v-btn                        
                            v-bind="props"
                            class="text-Keep-Calm-Book text-1B1464 text-size-title-contenido my-3"
                            >
                                CONTENIDO
                                <v-icon
                                end
                                icon="mdi-menu"
                                class="text-FF2929"
                                ></v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <v-list>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('quiensomos')" >
                        TUTORÍA
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('carrera')" >
                        Quienes somos
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('curso')" >
                        TUTORIALES
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('contacto')" >
                        SOLICITA INFORMACIÓ
                        </v-btn>
                    </v-list-item>
                    </v-list>
                </v-menu>         
                <v-menu >
                    <template v-slot:activator="{ props }">
                        
                        <div class="d-flex justify-end">
                            <v-btn                        
                            v-bind="props"
                            class="text-Keep-Calm-Book text-1B1464 text-size-title-contenido my-3"
                            >
                                SEDES
                                <v-icon
                                end
                                icon="mdi-menu"
                                class="text-FF2929"
                                ></v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <v-list>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('quiensomos')" >
                        TUTORÍA
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('carrera')" >
                        Quienes somos
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('curso')" >
                        TUTORIALES
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('contacto')" >
                        SOLICITA INFORMACIÓ
                        </v-btn>
                    </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-col>
</v-row>
</div>
</template>

<script>

import axios from 'axios'
var url ='backend-tesis/crud-categoria.php';


export default {
  name: 'TesisContenido',
  props: {
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,tipo:"P"})
      .then(Response =>{
        this.categorias=Response.data
      })
    },
   href(href){
    window.open(href);
   }
 },
  data(){
    return {
        categorias : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://www.tesis.com.bo/",
        baseImagen : "img-tesis/img-slider/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
    iftable() {
      if (screen.width > 0 && screen.width <= 960) {
        return true;
      }
      return false;
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.background-5bec76{
    background: #5bec76;
}
.background-1c7597{
    background: #1c7597;
}
.img-width{
    width : 50px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
    
.img-width{
    width : 50px;
}
    .text-size-title-contenido{
        font-size: 4.5vw;
    }
    .text-size-subtitle-contenido{
        font-size: 1.3vw;
    }
}
@media (min-width: 601px) and (max-width: 960px) { 
.img-width{
    width : 50px;
}
    .text-size-title-contenido{
        font-size: 4.5vw;
    }
    .text-size-subtitle-contenido{
        font-size: 1.3vw;
    }
 }
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    .text-size-title-contenido{
        font-size: 1.8vw;
    }
    .text-size-subtitle-contenido{
        font-size: 1.3vw;
    }
    .text-size-icon{
        font-size: 3vw;
    }
    .text-size-icon1{
        font-size: 2vw;
    }
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    .text-size-title-contenido{
        font-size: 2vw;
    }
    .text-size-subtitle-contenido{
        font-size: 1.2vw;
    }
    .text-size-icon{
        font-size: 3.2vw;
    }
    .text-size-icon1{
        font-size: 1.5vw;
    }
 }
@media (min-width: 1905px)  { 
    .text-size-title-contenido{
        font-size: 1.8vw;
    }
    .text-size-subtitle-contenido{
        font-size: 1.1vw;
    }
    .text-size-icon{
        font-size: 3.2vw;
    }
    .text-size-icon1{
        font-size: 1.2vw;
    }

}


</style>
