<template>
    <div class="my-12">
      <h3 class="text-Keep-Calm-Heavy text-1B1464 text-center padding-20 text-size-titulo-m"> PARTNERS Y ASOCIADOS</h3>
    <v-row justify="center" class="padding-20">
      <v-col
        cols="10"
        class="d-flex justify-center"
        v-if="(asociados.length) < 11"
      >
        <v-row justify="center">
          <v-col
          :cols="columns"
          v-for="(socio, index) in asociados" v-bind:key="index"
          >           
            <v-img
                :src="baseUrl+''+baseImagen+''+socio.imagen"
                width="100%"
                aspect-ratio="1"
                class="cursor"
                @click="logohref(socio.enlace)"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
   
    </div>
    
    
</template>

<script>
import axios from 'axios'
var url ='backend-tesis/crud-asociado.php';

export default {
  name: 'TesisAsociados',
  props: {
    id: Number,
  },
  components : {
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 3;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 1;
      }
      if (screen.width > 1264 && screen.width <= 1904) {
        return 1;
      }
      if (screen.width > 1904) {
        return 1;
      }

      return 1;
    },
  },
 methods: { 
  
   logohref(href){
    window.open(href);
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.asociados=Response.data
      })
    },   
 },
  data(){
    return {
      carouselheight: 0,
       //baseUrl : "http://localhost/",
       baseUrl : "https://www.tesis.com.bo/",
       baseImagen : "img-tesis/img-socio/",
       asociados : [],
    };
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

/** fin Banner */

@media (min-width: 0px) and (max-width: 600px) { 

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 

 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 

 }
@media (min-width: 1905px)  { 

 }



</style>
