<template>  
<v-card>
  <v-toolbar
    :height="height_toolbar"
    class="background-1B1464 text-white ">
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-img src="../../assets/Logos/tesis.png"></v-img>
      
      <v-btn  icon="mdi-home" ></v-btn>
      <v-btn  icon="mdi-magnify" v-if="!ifnabar"></v-btn>
      <v-btn  class="background-FF2929 text-1B1464" icon="mdi-account" rounded="lg" v-if="!ifnabar"></v-btn>
      <v-menu v-if="!ifnabar" >
        <template v-slot:activator="{ props }">
          
          <v-app-bar-nav-icon variant="text" 
            class="ma-2 text-FF2929"
            v-bind="props"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('quiensomos')" >
              TUTORÍA
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('carrera')" >
              Quienes somos
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('curso')" >
              TUTORIALES
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  class="text-Keep-Calm-Medium" v-on:click="changeFocus('contacto')" >
              SOLICITA INFORMACIÓ
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      

      <v-menu v-if="ifnabar" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            
            v-bind="props"
            class="text-Keep-Calm-Medium text-narbar-tam"
          >
          TUTORÍA          
          </v-btn>
        </template>
        <v-list >
            <v-list-item >
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >DOCTORADOS</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item >
              <v-btn variant="text" class="text-black  text-Keep-Calm-Medium"   >
                  <v-list-item-title  >MAESTRÍAS</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item >
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >ESPECIALIDADES</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item >
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >DIPLOMADOS</v-list-item-title>
                </v-btn>
            </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="ifnabar" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            
            v-bind="props"
            class="text-Keep-Calm-Medium text-narbar-tam"
          >
          Quienes somos
          
          </v-btn>
        </template>
        <v-list >
            <v-list-item>
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >¿POR QUE ELEGIRNOS?</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >MISIÓN, VISIÓN</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >VALORES INSTITUCIONALES</v-list-item-title>
                </v-btn>
            </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="ifnabar" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            
            v-bind="props"
            class="text-Keep-Calm-Medium text-narbar-tam"
          >
          TUTORIALES          
          </v-btn>
        </template>
        <v-list >
            <v-list-item>
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >TUTORIALES FREE</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn variant="text" class="text-black text-Keep-Calm-Medium"   >
                  <v-list-item-title  >TUTORIALES PREMIUM</v-list-item-title>
                </v-btn>
            </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="ifnabar" variant="text" class ="text-Keep-Calm-Medium text-narbar-tam " v-on:click="changeFocus('contacto')">SOLICITA<br v-if="br"> INFORMACIÓN</v-btn>
       <v-autocomplete 
          v-if="ifnabar"
            v-model="model"
            v-model:search-input="search"
            chips
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="symbol"
            label="Buscar"
            solo
          >
          </v-autocomplete>
      <v-btn v-if="0 == 1" icon="mdi-cart" v-on:click="changeFocus('contacto')"></v-btn>
      <v-btn v-if="ifnabar" prepend-icon="mdi-account" class ="text-Keep-Calm-Medium background-1C75A2 text-narbar-tam" v-on:click="changeFocus('contacto')"> Accceder</v-btn>
      <v-menu v-if="ifnabar">
        <template v-slot:activator="{ props }">
          
          <v-app-bar-nav-icon variant="text" 
            class="ma-2 "
            v-bind="props"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-btn variant="text text-Keep-Calm-Medium text-narbar-tam"  v-on:click="changeFocus('quiensomos')" >
              Noticias
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text text-Keep-Calm-Medium text-narbar-tam"  v-on:click="changeFocus('carrera')" >
              Tu Mentor Coach
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text text-Keep-Calm-Medium text-narbar-tam"  v-on:click="changeFocus('curso')" >
              Testimonios
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text text-Keep-Calm-Medium text-narbar-tam"  v-on:click="changeFocus('contacto')" >
              Preguntas frecuentes
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
  </v-toolbar>  
  </v-card>

</template>

<script>

export default {
  name: 'TesisNabar',
  props: {
  },
  components : {
  },
  computed: {
    ifnabar(){
      
      if (screen.width <= 960 ) {
        return false;
      }
      return true;
    },
    height_toolbar() {
      if (screen.width > 0 && screen.width <= 600) {
        return 75;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 90;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 100;
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return 100;
      }

      if (screen.width > 1904) {
        return 110;
      }

      return 1;
    },
    br(){
       if (screen.width > 0 && screen.width <= 1264) {
        return true;
      }
        return false;
    }
      
  },
 methods: {
    changeFocus (hash) {
     console.log(hash);
     window.location.hash = hash;
  },
   
 },
  data(){
    return {
    };
  },

    watch: {
    },
  mounted(){
    }

      

}
</script>

<style scoped>


/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .img-padding{   
    padding: 12% 0% 0% 73%;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
    .img-padding{   
    padding: 10% 0% 0% 90%
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .img-padding{   
    padding: 7% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1vw
  }
  .margin-img{
    margin: 0px 0px 0px 80px
  }
  .margin-booton{
    margin: 0px 120px 0px 0px;
  }
     
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
     .img-padding{   
    padding: 5% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1.1vw
  }
  .margin-img{
    margin: 0px 0px 0px 125px;
  }
  .margin-booton{
    margin: 0px 150px 0px 0px;
  }
  
 }
@media (min-width: 1905px)  { 
     .img-padding{   
    padding: 4.5% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 0.9vw
  }
  .margin-img{
    margin: 0px 0px 0px 250px;
  }
  .margin-booton{
    margin: 0px 250px 0px 0px;
  }
}


</style>
