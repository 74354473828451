<template>
<div>
  <v-row justify="center">
      <v-col cols="12">
          <v-row justify="center">
              <v-col class="padding-col"
                cols="12"
                sm="12"
                md="7"
                lg="7"
                xl="7">  
                  <v-col cols="12" class=" background-t div-ta1 padding-col">
                  </v-col> 
                  <div class="d-flex justify-center my-6">
                    <h3 class="background-1B1464 text-center text-MyriadPro-Regular text-white padding-text-numero text-size-llamada">Si tienes dudas, te llamamos</h3>
                  </div>
                  <v-row justify="center">
                    <v-col cols="10">
                      <v-row justify="center">
                        <v-col cols="12">
                          <h3 class="text-Keep-Calm-Book mb-2 text-size-numero">Número de celular</h3>
                        </v-col>
                        <v-col
                        cols="5"
                        sm="3"
                        md="3"
                        lg="3"
                        xl="2">
                             <v-btn
                          rounded="lg"
                          class="background-1B1464 text-white"
                          height="50"
                          >
                              <v-img
                              src="../../assets/bolivia.webp"
                              aspect-ratio="1"
                              class="tan-bandera "
                              >
                              </v-img>
                              <v-icon>mdi-chevron-down</v-icon> +591
                          </v-btn>
                        </v-col>
                        
                        <v-col
                        cols="7"
                        sm="9"
                        md="4"
                        lg="5"
                        xl="7">
                          <v-text-field
                              variant="outlined"
                              block
                              class="text-001C29"
                              label="Celular"
                              required
                          ></v-text-field>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="12"
                        md="5"
                        lg="4"
                        xl="3">
                        <div class="d-flex justify-center">
                          <v-btn 
                          class="background-1B1464  text-white text-Keep-Calm-Book mb-6 " 
                          href="tel:65262795"
                          height="50" 
                          :size="height_bootom"
                          rounded="pill">
                          Llámame ahora
                          </v-btn>
                        </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
              </v-col>
              <v-col class="background-t"
                cols="12"
                sm="12"
                md="5"
                lg="5"
                xl="5">
                
                  <div class="margin-20 padding-subcribete">
                    <h2 class="text-white text-MyriadPro-Regular text-size-title">SUSCRÍBETE</h2>
                    <p class="text-white text-Keep-Calm-Book mb-6 text-size-descripcion">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh eu</p>
                    <v-text-field
                      label="Gmail"
                      placeholder="tu_email@correo.com"
                      bg-color="white"
                    ></v-text-field>
                    <div class="d-flex justify-center">
                      <v-btn 
                      class="background-FF2929 text-white text-Keep-Calm-Book mb-6" 
                      :size="height_bootom"
                      rounded="pill">
                      SUSCRÍBETE
                      </v-btn>
                    </div>
                  </div>
              </v-col>
          </v-row>
      </v-col>
  </v-row>  
</div>
</template>

<script>

import axios from 'axios'
var url ='backend-tesis/crud-categoria.php';


export default {
  name: 'TesisContacto',
  props: {
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,tipo:"P"})
      .then(Response =>{
        this.categorias=Response.data
      })
    },
   href(href){
    window.open(href);
   }
 },
  data(){
    return {
        categorias : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://www.tesis.com.bo/",
        baseImagen : "img-tesis/img-slider/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "large";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>
.padding-subcribete{
  padding-left: 5%;
  padding-right: 10%;
}
.tan-bandera{
    width : 32px;
}
.padding-text-numero{
  padding: 5px 15px 5px 15px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
.padding-subcribete{
  padding-left: 5%;
  padding-right: 10%;
}
.text-size-descripcion{
  font-size: 3vw;
}
.text-size-title{
  font-size: 7vw;
}
}
@media (min-width: 601px) and (max-width: 960px) { 
 .padding-subcribete{
  padding-left: 5%;
  padding-right: 10%;
}
.text-size-descripcion{
  font-size: 2.5vw;
}
.text-size-title{
  font-size: 6vw;
}
.text-size-llamada{
  font-size: 5vw;
}
.text-size-numero{
  font-size: 3vw;
}
 }
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    .padding-subcribete{
  padding-left: 5%;
  padding-right: 10%;
}
.text-size-descripcion{
  font-size: 1.5vw;
}
.text-size-title{
  font-size: 3vw;
}

.text-size-llamada{
  font-size: 3vw;
}
.text-size-numero{
  font-size: 2vw;
}
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
.padding-subcribete{
  padding-left: 5%;
  padding-right: 10%;
}
.text-size-descripcion{
  font-size: 1vw;
}
.text-size-title{
  font-size: 2.5vw;
}

.text-size-llamada{
  font-size: 2.5vw;
}
.text-size-numero{
  font-size: 2vw;
}
 }
@media (min-width: 1905px)  { 
 .padding-subcribete{
  padding-left: 5%;
  padding-right: 10%;
}
.text-size-descripcion{
  font-size: 1vw;
}
.text-size-title{
  font-size: 2.1vw;
}

.text-size-llamada{
  font-size: 2.1vw;
}
.text-size-numero{
  font-size: 1.7vw;
}   

}


</style>
