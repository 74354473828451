<template>
<div  class="background-281C47 margin-50"> 
  <div class="padding-20">
      <h3 class="text-Keep-Calm-Heavy text-DA2F42 text-center text-size-titulo-"> NUESTRO EQUIPO</h3>
      <h3 class="text-Keep-Calm-Heavy text-DA2F42 text-center text-size-titulo-"> UN GRUPO DE COACH COMPROMETIDOS CON TU PROYECTO ACADÉMICO</h3>
  </div>
  <div class="padding-20">
    <v-carousel
        cycle
        height="max-height"
        hide-delimiters
      >
      
      <template v-slot:prev="{ props }">
          <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
          ><v-icon class="text-D24748 size-icon-lr">mdi-menu-left</v-icon></v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
          ><v-icon class="text-D24748 size-icon-lr">mdi-menu-right</v-icon></v-btn>
        </template>  
      <v-row justify="center">
        <v-col
          cols="10"   >     
        
        <v-carousel-item  
          v-for="(j, i) in 
          (Math.ceil(motivaciones.length/columns))"
          v-bind:key="i"
          >
        
            <v-row >
              <template
              v-for="(moti, index) in motivaciones" v-bind:key="index" >
              <v-col  
                :cols="col_tam"
                v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
              >
              
              <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                  <div
                    class="mx-auto background-281C47 mb-6"
                    max-width="344"
                  >
                  
                  <v-row justify="center" class="mb-6">
                    <v-col
                      cols="6"
                    >
                      <v-img
                            :src="baseUrl+''+baseImagen+''+moti.imagen"
                            width="100%"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-center">
                    <span class="text-white text-keep-calm-font text-center size-text-titulo padding-titulo">{{moti.nombre }}</span>
                  </div>                    
                    <div class="d-flex justify-center padding-20">
                      
                        <v-btn class="text-Keep-Calm-Book background-982929 text-white nargin-booton" >
                          Más detalles
                        </v-btn>
                        <v-btn class="text-Keep-Calm-Book background-982929 text-white nargin-booton" >
                          <v-icon>mdi-menu-right</v-icon>
                        </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
              </template>
            </v-row>
            
        </v-carousel-item>
        </v-col>
      </v-row>
      </v-carousel>
  </div>   
</div>

</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';

import axios from 'axios'
var url ='backend-tesis/crud-docente.php';

export default {
  name: 'TesisDocente',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.motivaciones=Response.data
      })
    },
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1264 && screen.width <= 1904) {
        return 4;
      }
      if (screen.width > 1904) {
        return 4;
      }

      return 1;
    },
    col_tam() {
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264 && screen.width <= 1904) {
        return 3;
      }
      if (screen.width > 1904) {
        return 3;
      }

      return 1;
    }
  },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://www.tesis.com.bo/",
      baseImagen : "img-tesis/img-docente/",
      motivaciones : [],

    }
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>

@media (min-width: 0px) and (max-width: 600px) { 
  
 .video-width{
    width: 100px;
  }
  .size-text-titulo{
    font-size: 1.9vh;
  }
  .padding-titulo{
    padding: 0% 6% 0% 6%;
  }
  .size-text-descripcion{
    font-size: 2vh;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
   .height-descripcion{
    height: 105px;
  }
  .size-text-descripcion{
    font-size: 1.5vh;
  }
  .video-width{
    width: 180px;
  }
  
  .size-text-titulo{
    font-size: 1.8vh;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.video-width{
    width: 200px;
  }
   .height-descripcion{
    height: 80px;
  }
  .size-text-descripcion{
    font-size: 1.4vh;
  }
  .size-text-titulo{
    font-size: 1.7vh;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
  .video-width{
    width: 240px;
  } 
   .height-descripcion{
    height: 85px;
  }
  .size-text-descripcion{
    font-size: 1.6vh;
  }
  .size-text-titulo{
    font-size: 1.8vh;
  }

 }
@media (min-width: 1905px)  { 
  .size-text-titulo{
    font-size: 2vh;
  }
  
   .height-descripcion{
    height: 80px;
  }
  .size-text-descripcion{
    font-size: 1.6vh;
  }
  
  .size-text-titulo{
    font-size: 1.8vh;
  }
 }

</style>
