<template>
<div class="margin-50">
    <v-row justify="center">
      <v-col 
      cols="12">
          <v-row justify="center">
            <v-col
              cols="10"
            >    
            
                <v-row justify="center">
                  <template
                  v-for="(servicio, index) in servicios" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam"
                  >
                    <v-img
                          :src="baseUrl+''+baseImagen+''+servicio.imagen"
                          width="100%"
                          aspect-ratio="1"
                          class="cursor"
                      >
                        <p class="text-white text-Keep-Calm-Heavy size-text-titulo p-text text-center ">{{servicio.titulo}}</p>   
                      </v-img>
                    <v-img
                          :src="baseUrl+''+baseImagen+''+servicio.imagen2"
                          width="100%"
                          aspect-ratio="1"
                          class="cursor"
                          v-if="false"
                      >
                        <p class="text-white text-Keep-Calm-Heavy size-text-titulo p-text text-center ">{{servicio.titulo}}</p>   
                      </v-img>
                      
                  </v-col>
                  </template>
                </v-row>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-tesis/crud-servicio.php';


export default {
  name: 'TesisServicios',
  props: {
  },
  components : {
  },
 methods: {
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.servicios=Response.data
      })
    }, 

    ViewCarrera(carrera1){
      console.log(carrera1);
      if(carrera1.id==1){
        this.enviarCarrera(carrera1.id);
      }else{
        this.enviarCurso(carrera1.id);
      }
    },
    enviarCarrera(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false)
     this.$emit("carrera",true),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
  enviarCurso(id){
    console.log(id);
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("curso",true),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
   
 },
  computed: {
    ifImg(){
      if (screen.width > 0 && screen.width <= 600) {
        return true;
      }
      return false
    },
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
        
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 961 ) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data(){
    return {
      x1 : true,
      //baseUrl : "http://localhost/",
      baseUrl : "https://www.tesis.com.bo/",
       baseImagen : "img-tesis/img-servicio/",
      servicios : [],
    };
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .p-text{
    padding: 0% 0% 30% 0%;
}
 .size-text-titulo{
    font-size: 1.8vh;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
.p-text{
    padding: 0% 0% 30% 0%;
}
.size-text-titulo{
    font-size: 1.8vh;
}
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .p-text{
    padding: 36% 10% 0% 10%;
}
.size-text-titulo{
    font-size: 2.2vh;
}
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .p-text{
    padding: 36% 10% 0% 10%;
}
.size-text-titulo{
    font-size: 2.8vh;
}
 }
@media (min-width: 1905px)  { 
.p-text{
    padding: 36% 10% 0% 10%;
}
.size-text-titulo{
    font-size: 3.5vh;
}
}


</style>
